<!--  -->
<template>
  <div>
    <pHeader />
    <div class="banner02">
      <div class="inner">
        <div class="tit">企业版开发框架</div>
        <p class="des">
          企业版前端采用vue3.0，界面更为漂亮<br/>支持工作流采用了flowable引擎<br/>微服务架构 分布式开发及部署<br/>增加了智能搜索 所有菜单均可文字+语音进行搜索<br/>
          统一认证登录平台 支持多系统多集成<br/>统一消息推送 消息管控更加到位
        </p>
        <div class="group layout-flex">
          <div class="btn before"><a href="http://demo.icuapi.com" target="_blank">前端体验</a></div>
          <div class="btn after"><a href="http://demo.icuapi.com" target="_blank">后端体验</a></div>
        </div>
        <img src="@/assets/img/banner02_img.png" alt />
      </div>
    </div>
    <div class="function">
      <div class="inner">
        <div class="header-title">
          <div class="tit">我们的核心功能</div>
          <p class="tips">我们将自身的业务沉淀 打造成一套系统，发挥技术优势，为客户提供全方位技术服务。</p>
        </div>
        <div class="functionBox layout-flex">
          <div class="item" v-for="(item,index) in core" :key="index">
            <img :src="item.img" alt />
            <span>{{item.name}}</span>
            <div class="hover">
              <img :src="item.activeImg" alt />
              <span>{{item.name}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mainFunction">
      <div class="inner">
        <img src="@/assets/img/introduce1-arrow.png" alt class="arrow" />
        <div class="left">
          <div class="name">增加你的开发速度</div>
          <p>
            企业信息化路上的业务沉淀 将众多的基础工作进行封装 开发者直接进行使用
          </p>
          <p>

          </p>
          <p></p>
        </div>
        <div class="right">
          <img src="@/assets/img/introduce1-notebook.png" alt />
        </div>
      </div>
    </div>
    <div class="functionSup">
      <div class="inner">
        <div class="header-title">
          <div class="tit">页面展示</div>
          <p class="tips">产品级的开发框架 完善的业务逻辑 能让项目快速落地的开发框架</p>
        </div>
        <div class="img">
          <img src="@/assets/img/introduce2-Webpage.png" alt />
        </div>
      </div>
    </div>
    <div class="functionDec">
<!--      <div class="inner layout-flex">-->
<!--        <div class="left">-->
<!--          <div class="name">我是啥啥的功能</div>-->
<!--          <p>这是我滴功能介绍功能介绍功能介绍</p>-->
<!--          <p>-->
<!--            这是我滴功能介绍功能介绍功能介绍这是我-->
<!--            滴功能介绍功能介绍功能介绍-->
<!--          </p>-->
<!--        </div>-->
<!--        <div class="right">-->
<!--          <img src="@/assets/img/introduce3-Webpage.png" alt />-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <div class="otherFunction">
      <div class="inner">
        <div class="header-title">
          <div class="tit">提供的支持</div>
        </div>
        <div class="box layout-flex">
          <div class="item" >
            <img src="@/assets/img/Otherfunctions-001.png" alt />
            <div class="cate">视觉设计规范</div>
            <div class="dec">
              可以提供给客户方的框架产品视觉设计规范
            </div>
          </div>
          <div class="item">
            <img src="@/assets/img/Otherfunctions-001.png" alt />
            <div class="cate">产品白皮书</div>
            <div class="dec">
              可以提供给客户方的产品设计白皮书
            </div>
          </div>
          <div class="item" >
            <img src="@/assets/img/Otherfunctions-001.png" alt />
            <div class="cate">数据库设计说明书</div>
            <div class="dec">
              可以提供给客户方的产品数据库设计说明书
            </div>
          </div>
          <div class="item" >
            <img src="@/assets/img/Otherfunctions-001.png" alt />
            <div class="cate">交付大礼包</div>
            <div class="dec">
              内含技术架构选型、xxx验收报告、xxx项目试运行报告、xxx试运行方案 4份文件
            </div>
          </div>
        </div>
      </div>
    </div>
    <pFooter />
  </div>
</template>

<script>
export default {
  data () {
    return {
      core: [{ name: "智能辅助", img: require('@/assets/img/function-001.png'), activeImg: require('@/assets/img/function-click-001.png') },
      { name: "部门管理", img: require('@/assets/img/function-002.png') },
      { name: "角色管理", img: require('@/assets/img/function-003.png') },
      { name: "用户管理", img: require('@/assets/img/function-004.png') },
      { name: "系统日志", img: require('@/assets/img/function-005.png') },
      { name: "行政区域", img: require('@/assets/img/function-006.png') },
      { name: "行业选择", img: require('@/assets/img/function-007.png') },
      { name: "自定义表单", img: require('@/assets/img/function-008.png') },
      { name: "流程管理", img: require('@/assets/img/function-009.png') },
      { name: "CMS内容管理", img: require('@/assets/img/function-009.png') }]

    };
  },

  components: {},

  computed: {},

  mounted: {},

  methods: {}
}

</script>
<style lang='scss' scoped>
a{
  color: inherit;
}
.banner02 {
  min-width: 1200px;
  width: 100%;
  height: 78rem;
  background: #f8f9fa;
  background-image: url('../../../../assets/img/banner02.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  .inner {
    padding-top: 9rem;
    height: 100%;
    margin: 0 auto;
    width: $minWidth;
    position: relative;
    color: #fff;
    text-align: center;
    .tit {
      margin-bottom: 2rem;
      font-size: 3rem;
    }
    .des {
      margin-bottom: 4rem;
      padding: 0 10rem;
      line-height: 1.7;
      text-indent: 1rem;
      font-size: 1.4rem;
    }
    .group {
      justify-content: center;
      .btn {
        margin: 0 2rem;
        width: 10rem;
        height: 4rem;
        border: 1px solid #fff;
        border-radius: 0.8rem;
        &:hover {
          background: #fff;
          color: #3981f7;
        }
      }
    }
    img {
      width: 110rem;
      position: absolute;
      bottom: 1rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
.function {
  padding: 8rem 0 12rem;
  background: #f8f9fa;
  .inner {
    width: $minWidth;
    margin: 0 auto;
    .functionBox {
      flex-wrap: wrap;
      .item {
        margin-bottom: 2rem;
        width: 20.8rem;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        &:not(:nth-of-type(5n)) {
          margin-right: 4rem;
        }
        img {
          width: 100%;
          height: 100%;
        }
        span {
          font-size: 1.4rem;
          position: absolute;
          top: 60%;
          left: 50%;
          transform: translateX(-50%);
        }
        .hover {
          position: absolute;
          bottom: 14rem;
          left: 0;
          transition: bottom 0.4s;
          span {
            top: 52%;
            color: #fff;
          }
        }
        &:hover {
          .hover {
            bottom: 0;
          }
        }
      }
    }
  }
}
.mainFunction {
  width: 100%;
  height: 78rem;
  background: #0773fc;
  position: relative;
  .inner {
    padding: 10rem 8rem;
    width: $minWidth;
    height: 100%;
    margin: 0 auto;
    .arrow {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
    }
    .left {
      width: 50%;
      color: #fff;
      position: relative;
      z-index: 999;
      .name {
        margin-bottom: 2rem;
        font-size: 2.2rem;
      }
      p {
        margin-bottom: 1rem;
        line-height: 1.8;
        font-size: 1.6mrem;
      }
    }
    .right {
      height: 60rem;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      img {
        height: 100%;
      }
    }
  }
}
.functionSup {
  padding: 6rem 0 4rem;
  .inner {
    width: $minWidth;
    margin: 0 auto;
    .img {
      width: 80%;
      margin: 5rem auto 0;
      img {
        width: 100%;
      }
    }
  }
}
.functionDec {
  padding: 5rem 0;
  background: #f3f5fb;
  .inner {
    padding: 0 2rem;
    width: $minWidth;
    margin: 0 auto;
    .left {
      width: 50%;
      .name {
        margin-bottom: 1.5rem;
        font-size: 1.8rem;
      }
      p {
        color: #77838f;
        font-size: 1.4rem;
        line-height: 1.8;
      }
    }
    .right {
      width: 50%;
      img {
        width: 100%;
      }
    }
  }
}
.otherFunction {
  padding: 6rem 0;
  .inner {
    width: $minWidth;
    margin: 0 auto;
    .box {
      flex-wrap: wrap;
      width: 100%;
      .item {
        margin-bottom: 2rem;
        width: 33.3%;
        padding: 4rem 8rem;
        cursor: pointer;
        box-shadow: none;
        transition: all 0.5s;
        img {
          width: 20%;
          margin-bottom: 0.8rem;
        }
        .cate {
          margin-bottom: 1rem;
          font-size: 1.6rem;
        }
        .dec {
          color: #3c4858;
          font-size: 1.2rem;
        }
        &:hover {
          box-shadow: 1px 1px 6px 2px #ddd;
        }
      }
    }
  }
}
</style>